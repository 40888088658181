import { Locale } from '@happywait/hw-lib-front.core.api'
import i18n, { FormatFunction } from 'i18next'
import Backend from 'i18next-xhr-backend'
import { TFunction, initReactI18next, useTranslation } from 'react-i18next'

export const useTranslate = (ns?: string) => {
  const { t } = useTranslation(ns, { useSuspense: true })
  return t
}
export const useCompleteTranslate = (ns?: string) => {
  const { t, i18n } = useTranslation(ns, { useSuspense: true })
  return { t, exists: i18n.exists }
}

type Options = Record<string, string | number>

export const useCountTranslate = (t: TFunction<string, undefined>) => (key: string, options: Options) =>
  t(`${key}_${options.count}`, options) || t(key, options)

const t = (key: string, options?: Options) => i18n.t(key, options)

const fixedT = (lng: string, ns?: string, keyPrefix?: string) => i18n.getFixedT(lng, ns, keyPrefix)

const interpolationFormat: FormatFunction = (value, ...args) => {
  if (value === undefined) {
    return ''
  }

  return value
}

const init = (currentLocale: Locale, callback: (() => void) | undefined = undefined) =>
  i18n
    .use(Backend)
    .use(initReactI18next)
    .init({
      backend: {
        loadPath:
          process.env.NODE_ENV === 'development'
            ? '/locales/{{lng}}/{{ns}}.json'
            : `${process.env.PUBLIC_URL}/locales/{{lng}}/{{ns}}.json`,
      },
      defaultNS: 'common',
      lng: formatLanguage(currentLocale),
      fallbackLng: ['en-DEFAULT'],
      load: 'currentOnly',
      partialBundledLanguages: true,
      supportedLngs: ['en-DEFAULT', 'fr-FR', 'pt-PT', 'es-ES', 'en-GB', 'nl-NL'],
      ns: [
        'common',
        'account',
        'acquisition',
        'checklist',
        'choices-options',
        'document',
        'funding-appeal',
        'home',
        'login',
        'messaging',
        'personalization',
        'pictures',
        'services',
        'snags',
      ],
      debug: process.env.NODE_ENV === 'development',

      interpolation: {
        escapeValue: false,
        format: interpolationFormat,
      },
    })

const changeLanguage = (locale: Locale) => i18n.changeLanguage(formatLanguage(locale))

const formatLanguage = (lang: string) => lang.replace('_', '-')

const getLanguage = () => i18n.language

export const _i18n = {
  init,
  t,
  fixedT,
  changeLanguage,
  formatLanguage,
  getLanguage,
}
