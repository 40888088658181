import { useMedias } from '@happywait/hw-lib-front.core.api'
import { ObserverWrapper } from 'components/Observer/ObserverWrapper'
import useNavigateParams from 'hooks/useNavigateParams'
import { useSetCurrentUnit } from 'hooks/useSetCurrentUnit'
import { PropsWithChildren } from 'react'
import { useMatch } from 'react-router-dom'
import { useStore } from 'store'
import { selectInitialData } from 'store/selectors'
import { useBaseDev } from '../../hooks/useBaseDev'
import { useDevice } from '../../hooks/useDevice'
import { useQueryString } from '../../hooks/useQueryString'
import { routesWithoutMenu } from '../../modules'
import { generateThumbUrl } from '../../tools/thumb'
import { Menu } from '../Menu/Menu'
import { LayoutStyles as Styles } from './Layout.styles'

export const Layout = (props: PropsWithChildren) => {
  const routesWithoutMenuMatch = useMatch({ path: routesWithoutMenu })
  const isMobile = useDevice('mobileL')
  const navigate = useNavigateParams()
  const baseDev = useBaseDev()
  const formType = useQueryString('formType')

  const hideMenu = (!!formType && isMobile) || (!!routesWithoutMenuMatch && isMobile)

  useSetCurrentUnit()
  const initialData = useStore(selectInitialData)
  const { data: promoterLogo } = useMedias({
    params: { ids: [initialData?.logo || 0] },
    queryParams: { enabled: !!initialData?.logo },
  })

  return (
    <>
      <ObserverWrapper>
        <Styles.Header>
          {promoterLogo?.data[0] && (
            <Styles.Logo
              onClick={() => navigate('/home')}
              src={
                promoterLogo.data[0].fileWebPath.substring(promoterLogo.data[0].fileWebPath.lastIndexOf('.')) === '.svg'
                  ? baseDev + promoterLogo.data[0].fileWebPath
                  : generateThumbUrl({ thumbType: 'logo', url: promoterLogo.data[0].fileWebPath })
              }
            />
          )}
        </Styles.Header>
        <Styles.SubContainer>
          {!hideMenu && <Menu />}
          <Styles.Content hideMenu={hideMenu}>{props.children}</Styles.Content>
        </Styles.SubContainer>
      </ObserverWrapper>
    </>
  )
}
