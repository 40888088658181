import { useEffect, useState } from 'react'

export const useIsOnValidating = (isDirty: boolean, isLoading: boolean, debouncedPhone: any, value: string) => {
  const [isOnValidating, setIsOnValidating] = useState(false)

  useEffect(() => {
    setIsOnValidating(isDirty && (isLoading || debouncedPhone != value))
  }, [isDirty, isLoading, debouncedPhone, value])
  return isOnValidating
}
