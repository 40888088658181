import { Box, Icon, Spinner, Text, Tooltip, useTheme } from '@chakra-ui/react'
import { getColor } from '@chakra-ui/theme-tools'
import { E164Number } from 'libphonenumber-js/core'

import { InfoCircle } from 'assets/svg/InfoCircle'
import { TooltipWrapper } from 'design-system/TooltipWrapper/TooltipWrapper'
import PhoneInput from 'react-phone-number-input'
import { omit } from 'remeda'
import { useStore } from 'store'
import { selectLocale } from 'store/selectors'
import { when } from 'utils/helpers'
import { PhoneNumberInputServices } from './PhoneNumberInput.services'
import { getPhoneNumberInputStyles } from './PhoneNumberInput.styles'

export type PhoneNumberInputProps = {
  value?: E164Number
  onChange: (value: E164Number) => void
  onBlur?: () => void
  placeholder?: string
  disabled?: boolean
  isLoading?: boolean
  title?: string
  error?: string
  errTooltip?: string
  name?: string
  dataTest?: string
  tooltip?: string | null
}

export const PhoneNumberInput = ({
  value,
  onChange,
  onBlur,
  placeholder,
  disabled,
  title,
  error,
  errTooltip,
  dataTest,
  isLoading,
  tooltip,
}: PhoneNumberInputProps) => {
  const theme = useTheme()
  const phoneNumberInputStyles = getPhoneNumberInputStyles(theme)
  const locale = useStore(selectLocale)

  return (
    <Box
      width="100%"
      sx={{
        '.PhoneInput': {
          ...phoneNumberInputStyles['.PhoneInput'],
          boxShadow: when(!!error, `0px 0px 0px 3px ${getColor(theme, 'orange.50')}`),
          border: when(!!error, `1px solid ${getColor(theme, 'orange.200')}`),
        },
        ...omit(phoneNumberInputStyles, ['.PhoneInput']),
      }}
    >
      {title && (
        <Text opacity="0.6" textAlign="start">
          {title}
        </Text>
      )}

      <TooltipWrapper label={tooltip} hidden={!tooltip}>
        <Box position={'relative'}>
          <PhoneInput
            flagUrl="https://purecatamphetamine.github.io/country-flag-icons/1x1/{XX}.svg"
            defaultCountry={PhoneNumberInputServices.getCountryCodeFromLocale(locale)}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            placeholder={placeholder}
            disabled={disabled}
            data-test={dataTest}
          />
          {isLoading && (
            <Box position="absolute" top="50%" right="10px" transform={'translateY(-25%)'}>
              <Spinner size={'sm'} color="blue.300" />
            </Box>
          )}
        </Box>
      </TooltipWrapper>
      {error && (
        <Text mt="2px" color="orange.200" fontSize="1.2rem" lineHeight="1.8rem">
          {error}
          {errTooltip && (
            <Tooltip label={errTooltip} variant="error">
              <Icon ml={1}>
                <InfoCircle fill="orange.200" />
              </Icon>
            </Tooltip>
          )}
        </Text>
      )}
    </Box>
  )
}
