import { Locale, Me, Media, NeoCivility } from '@happywait/hw-lib-front.core.api'
import { PatchUpdateUserParamsUser } from '@happywait/hw-lib-front.core.api/legacy'
import { SelectOptions } from 'design-system/Select/Select'
import i18next from 'i18next'
import Joi from 'joi'
import { isNil, keys, omit, toPairs } from 'remeda'
import { useTranslate } from 'services/i18n'
import { _locale } from 'services/locale'
import { _validator } from 'services/validator'
import { generateThumbUrl } from 'tools/thumb'

const tPrefix = 'forms.generalInformationForm'

export type FormatMode = 'person' | 'corporation'

type Avatar = {
  base64File: string
  category: string
  originalName: string
  fileWebPath?: string
}

export type ProfileFormValues = Pick<
  PatchUpdateUserParamsUser,
  | 'civility'
  | 'lastname'
  | 'firstname'
  | 'legalRepresentativeLastname'
  | 'legalRepresentativeFirstname'
  | 'capital'
  | 'sirenNumber'
  | 'rcsRegistrationPlace'
  | 'rcsNumber'
  | 'cellphone'
  | 'email'
  | 'address'
  | 'address2'
  | 'postCode'
  | 'city'
  | 'lang'
  | 'function'
> &
  Partial<{
    avatar: Avatar | null
    corporationStatus: string
  }>

export const useCivilityOptions = (): SelectOptions[] => {
  const t = useTranslate()

  return toPairs(NeoCivility).map(([key, value]) => ({ id: value, label: t(`civilities.${key}`) }))
}

const shared = {
  avatar: Joi.object<Avatar>({
    base64File: Joi.string().allow(''),
    category: Joi.string().allow(''),
    originalName: Joi.string().allow(''),
  }).allow(null),
  civility: Joi.string().required(),
  lastname: Joi.string().required(),
  email: _validator.joiEmailValidation,
  cellphone: Joi.string().allow('').custom(_validator.joiPhoneNumberValidation),
  address: Joi.string().allow(null, ''),
  address2: Joi.string().allow(null, ''),
  postCode: Joi.string().allow(null, ''),
  city: Joi.string().allow(null, ''),
  lang: Joi.string().custom(_validator.joiTypeLocaleValidation),
}

const fields = {
  person: {
    firstname: Joi.string().allow(''),
    function: Joi.string().allow(null, ''),
  },
  corporation: {
    corporationStatus: Joi.string().required(),
    legalRepresentativeLastname: Joi.string().allow(''),
    legalRepresentativeFirstname: Joi.string().allow(''),
    capital: Joi.number().allow(''),
    sirenNumber: Joi.string()
      .pattern(/^\d+(\s\d+)*$/) // allow whitespaces between numbers (123 123, 123123)
      .allow(''),
    rcsRegistrationPlace: Joi.string().allow(''),
    rcsNumber: Joi.string().allow(''),
  },
}

const schemas: Record<FormatMode, Joi.ObjectSchema<ProfileFormValues>> = {
  person: Joi.object<ProfileFormValues>({
    ...shared,
    ...fields.person,
  }),

  corporation: Joi.object<ProfileFormValues>({
    ...shared,
    ...fields.corporation,
  }),
}

const getSchema = (mode: FormatMode) => schemas[mode]

const omitMap: Record<FormatMode, any> = {
  corporation: keys(fields.person),
  person: keys(fields.corporation),
}

// This function only returns necessary form values
const formatterValues = (mode: FormatMode, avatar: Media | undefined) => (values: ProfileFormValues) => {
  const nextValues: ProfileFormValues = {}

  for (const key in values) {
    // Add avatar only if url has been updated
    if (key === 'avatar') {
      if (
        generateThumbUrl({ url: avatar?.fileWebPath, thumbType: 'user' }) !== values.avatar?.base64File &&
        values.avatar?.base64File &&
        values.avatar?.originalName
      ) {
        nextValues.avatar = values.avatar
      }
      continue
    }

    // backend is sending capital as number but required patch/post as string
    if (key === 'capital') {
      nextValues[key] = !isNil(values[key]) ? values[key]?.toString() : ''
      continue
    }

    nextValues[key] = values[key]
  }

  return omit(nextValues, omitMap[mode])
}

const getDefaultValues = ({
  user,
  locale,
  avatar,
}: {
  user?: Me
  locale: Locale
  avatar?: Media
}): ProfileFormValues => {
  const isCorporation = user && !isNil(user?.legalEntity)

  const defaultValues = {
    avatar: {
      base64File: avatar?.fileWebPath ? generateThumbUrl({ url: avatar.fileWebPath, thumbType: 'user' }) : '',
      category: 'MEDIA',
      originalName: avatar?.originalName || '',
      fileWebPath: avatar?.fileWebPath,
    },
    civility: isCorporation ? NeoCivility.corporation : user?.civility || '',
    lastname: isCorporation ? user?.legalEntity : user?.lastname,
    firstname: user?.firstname || '',
    function: user?.function || '',
    email: user?.email || '',
    cellphone: user?.cellphone || '',
    address: user?.address || '',
    address2: user?.address2 || '',
    postCode: user?.postalCode || '',
    city: user?.city || '',
    corporationStatus: user?.status || i18next.t('forms.generalInformationForm.company') || '',
    legalRepresentativeLastname: user?.legalRepresentativeLastname || '',
    legalRepresentativeFirstname: user?.legalRepresentativeFirstname || '',
    capital: !isNil(user?.capital) ? String(user?.capital) : '',
    sirenNumber: user?.sirenNumber || '',
    rcsRegistrationPlace: user?.rcsRegistrationPlace || '',
    rcsNumber: user?.rcsNumber || '',
  }

  if (_locale.isShowableLocalSelector()) defaultValues['lang'] = locale

  return defaultValues
}

export const generalInformationformServices = {
  getSchema,
  tPrefix,
  getDefaultValues,
  formatterValues,
}
