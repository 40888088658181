import { getBaseDev } from '../utils/helpers'

export type ThumbType =
  | 'home'
  | 'user'
  | 'user_2x'
  | 'user_3x'
  | 'logo'
  | 'logo_2x'
  | 'logo_3x'
  | 'contain'
  | 'contain_2x'
  | 'contain_3x'
  | 'crop'
  | 'crop_2x'
  | 'crop_3x'
  | 'timeline'
  | 'timeline_2x'
  | 'timeline_3x'
  | 'gallery'
  | 'gallery_2x'
  | 'gallery_3x'
  | 'gallery_zoom'
  | 'gallery_zoom_2x'
  | 'gallery_zoom_3x'
  | 'document_gallery'
  | 'document_gallery_2x'
  | 'document_gallery_3x'
  | 'icon'
  | 'daily_picture'

export const generateThumbUrl = ({
  url,
  thumbType,
  watermark,
}: {
  url?: string | null
  thumbType: ThumbType
  watermark?: boolean
}) => (url ? `${getBaseDev()}thumbs/${watermark ? 'watermark/' : ''}${thumbType}${url}` : '')
