import { Box, Text, useDisclosure, useOutsideClick } from '@chakra-ui/react'
import { IconButton } from '@chakra-ui/button'
import { Loader } from 'components/Loader/Loader'
import { SwitchChevron } from 'design-system/SwitchChevron/SwitchChevron'
import { ReactElement, ReactNode, useRef } from 'react'
import { useTheme } from 'styled-components'
import { EntityId } from 'types/entity'
import { when } from 'utils/helpers'
import { CloseSmall } from '../../assets/svg/CloseSmall'
import { MenuList } from '../MenuList/MenuList'
import { SelectStyles as Styles } from './Select.styles'

export type SelectOptions = {
  id: EntityId
  label: string
  component?: ReactElement
}

export type SelectProps = {
  options: SelectOptions[]
  value?: EntityId | null
  onSelect: (id: EntityId | null) => void
  placeholder?: string
  title?: string
  fullWidth?: boolean
  inverted?: boolean
  light?: boolean
  disabled?: boolean
  iconLeft?: ReactNode
  isLoading?: boolean
  removeSelection?: boolean
}

export const Select = ({
  options,
  value,
  onSelect,
  placeholder,
  title,
  fullWidth,
  inverted = false,
  light = false,
  disabled = false,
  iconLeft,
  isLoading,
  removeSelection = false,
}: SelectProps) => {
  const ref = useRef<HTMLDivElement | null>(null)
  const { isOpen, onClose, onToggle } = useDisclosure()

  useOutsideClick({
    ref,
    handler: onClose,
  })

  const theme = useTheme()

  const option = options.find(({ id }) => id === value)

  const onSelectOption = (id: EntityId) => {
    onSelect(id)
    onClose()
  }

  return (
    <Box position="relative" width={fullWidth ? '100%' : 'unset'} minW={100}>
      {title && <Styles.Title>{title}</Styles.Title>}
      <Styles.Select onClick={when(!disabled, onToggle)} light={light}>
        {iconLeft && (
          <Box id="select_icon" marginRight={2}>
            {iconLeft}
          </Box>
        )}
        <Text
          whiteSpace="nowrap"
          opacity={!option?.label ? 0.6 : 1}
          flex={1}
          color={disabled ? theme.colors.dark20 : theme.colors.black}
          textOverflow={'ellipsis'}
          overflow={'hidden'}
          paddingLeft={6}
        >
          {option?.label}
          {placeholder && !option?.label ? placeholder : ''}
        </Text>
        {!isLoading && (
          <SwitchChevron
            backgroundColor={theme.colors.grey20}
            color={disabled ? theme.colors.dark20 : theme.colors.black}
            active={isOpen}
            direction="left"
            inverted={inverted}
          />
        )}
        {!!isLoading && (
          <Box marginRight={3}>
            <Loader width={15} height={15} flex={'1'} />
          </Box>
        )}
        {removeSelection && !!value && (
          <IconButton
            variant="unstyled"
            color={'gray.400'}
            icon={<CloseSmall />}
            display="flex"
            minW="24px"
            w="24px"
            aria-label="close"
            onClick={(e) => {
              e.stopPropagation()
              e.currentTarget.focus()
              onSelect(null)
              onClose()
            }}
          />
        )}
      </Styles.Select>

      {isOpen && (
        <Styles.Menu ref={ref} inverted={inverted}>
          <MenuList options={options} onSelect={onSelectOption} />
        </Styles.Menu>
      )}
    </Box>
  )
}
