import { useCheckCellphone, useCheckPhone } from '@happywait/hw-lib-front.core.api'
import { useDebounce } from 'hooks/useDebounce'
import { useErrorTooltip } from 'hooks/useErrorTooltip'
import { useErrorTranslate } from 'hooks/useErrorTranslate'
import { E164Number } from 'libphonenumber-js/core'
import { useEffect } from 'react'
import { useController, useFormContext } from 'react-hook-form'
import { useTranslate } from 'services/i18n'
import { PhoneNumberInput } from './PhoneNumberInput'
import { useIsOnValidating } from './hook/usePhoneNumberInputIsOnValidating'

export type PhoneNumberInputControlledProps = {
  onChangeBefore?: (value: E164Number) => E164Number | string
  placeholder?: string
  disabled?: boolean
  isCellphone?: boolean
  title?: string
  name: string
  tErrorContext?: string
  dataTest?: string
  onValidating?: (_: boolean) => void
}

export const PhoneNumberInputControlled = ({
  onChangeBefore,
  placeholder,
  disabled,
  title,
  name,
  tErrorContext,
  dataTest,
  onValidating,
  isCellphone,
}: PhoneNumberInputControlledProps) => {
  const {
    field: { value, onChange },
    fieldState: { isDirty, error },
  } = useController({ name })
  const { setError, clearErrors, trigger } = useFormContext()
  const t = useTranslate()
  const tError = useErrorTranslate(tErrorContext)
  const errTooltip = useErrorTooltip(error)
  const errorTooltip = {
    'phone.invalid': isCellphone ? 'cellphone.invalid' : 'phone.invalid',
  }

  const debouncedPhone = useDebounce(isDirty && !error ? value : null, 500)

  const checkPhoneHook = isCellphone ? useCheckCellphone : useCheckPhone
  const { isLoading, data: dataCheckPhone } = checkPhoneHook({
    params: {
      phone: debouncedPhone,
    },
    queryParams: { enabled: !!debouncedPhone && isDirty },
  })
  const isOnValidating = useIsOnValidating(isDirty, isLoading, debouncedPhone, value)
  useEffect(() => onValidating?.(isOnValidating), [isOnValidating, onValidating])
  useEffect(() => {
    if (dataCheckPhone?.isValid === false) {
      setError(name, { type: 'phone.invalid' })
    } else if (dataCheckPhone?.isValid === true) {
      clearErrors(name)
      trigger(name)
    }
  }, [dataCheckPhone, name, setError, clearErrors, trigger])

  const phoneNumber = value as E164Number

  return (
    <PhoneNumberInput
      name={name}
      placeholder={placeholder}
      title={title}
      disabled={disabled}
      value={phoneNumber}
      onChange={(nextValue) => onChange(onChangeBefore ? onChangeBefore(nextValue) : nextValue)}
      error={tError(error)}
      dataTest={dataTest}
      isLoading={isLoading}
      tooltip={isLoading ? t('errors.form.phone.onValidating') : null}
      errTooltip={errTooltip(errorTooltip)}
    />
  )
}
